class GeneralVideo {
  constructor (element, config) {
    this.video = $(element);
    this.init();
  }

  init () {
    $('.nav-button').on('click', () => this.pauseVideo());
    $('.accessibility-navigation').on('click', () => this.pauseVideo());
    $('button').on('click', () => this.pauseVideo());
    $('video').not(this.video).on('play', () => this.pauseVideo());
    $('.youtube-lazy').on('play', () => this.pauseVideo());
    $('.vimeo-lazy').on('play', () => this.pauseVideo());

    const container = this.video;
    const thisFunction = this;
    const videoPos = container.offset().top;

    // stop playing on scrolling
    $(window).on('scroll', function () {
      var containerHeight = container.height();

      if ($(this).scrollTop() >= (videoPos + containerHeight) || $(this).scrollTop() <= (videoPos + containerHeight)) {
        thisFunction.pauseVideo();
      }
    });
  }

  playVideo () {
    const video = this.video.get(0);
    video.play();
  }

  pauseVideo () {
    const video = this.video.get(0);
    video.pause();
  }
}

export default GeneralVideo;
