class LazyVimeo {
  constructor (element) {
    this.isInitiated = false;
    this.videoContainer = $(element);
    this.playButton = this.videoContainer.find('.play-button');
    this.setupPrivacyChangeEvent();
    this.initIfApproved();
  }

  setupPrivacyChangeEvent () {
    const that = this;
    this.videoContainer.on('privacychange', () => {
      that.initIfApproved();
    });
  }

  initIfApproved () {
    if (this.isContentApproved() && !this.isInitiated) {
      this.init();
    }
  }

  isContentApproved () {
    return this.videoContainer.attr('data-privacy-content-approved') === 'approved';
  }

  init () {
    this.isInitiated = true;
    this.videoId = $(this.videoContainer).attr('data-embed');
    this.title = $(this.videoContainer).attr('data-title');
    this.loadImage();
    this.setupListener();
    this.setupScript();
  }

  /**
   * Tries to load the Vimeo API script in case it was not before
   */
  setupScript () {
    const scriptUrl = 'https://player.vimeo.com/api/player.js';
    let scriptElement = $(`script[src="${scriptUrl}"]`);

    if (scriptElement.length == 0) {
      scriptElement = $('<script>', {
        'src': scriptUrl,
      }).insertBefore($('script').first());
    }
  }

  /**
   * Check if the Vimeo API has loaded and initiate a Player once it is
   */
  checkApi () {
    if (Vimeo !== undefined) {
      this.player = new Vimeo.Player(this.iframe);
      this.player.on('play', () => {
        this.videoContainer.trigger('play');
      });
      this.player.on('pause', () => {
        this.videoContainer.trigger('pause');
      });
      this.player.on('ready', () => {
        this.videoContainer.trigger(this.videoContainer.getPaused() ? 'pause' : 'play');
      });

      return true;
    }

    return false;
  }

  /**
   * Sets up listeners for automatic pausing of playback caused by certain events
   */
  setupListener () {
    let func = (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.type === 'click' || (e.type === 'keypress' && (e.keyCode === 32 || e.keyCode === 13))) {
        this.createVideo();
      }
    };

    this.playButton.on('click keypress', (e) => func(e));
    this.videoContainer.on('click keypress', (e) => func(e));
  }

  /**
   * Creates the IFrame element of the player without appending in to the document
   */
  createIframe () {
    this.iframe = $('<iframe>', {
      'frameborder': '0',
      'allowfullscreen': '',
      'class': 'embed-responsive-item',
      'title': this.title,
      'src': `https://player.vimeo.com/video/${this.videoId}?api=1&autoplay=1`
    });
  }

  /**
   * Create and append video container
   */
  createVideo () {
    this.hidePlayButton();
    this.pauseVideo();
    this.createIframe();
    this.videoContainer.innerHTML = '';
    this.videoContainer.append(this.iframe);

    $('.nav-button').on('click', () => this.pauseVideo());
    $('.accessibility-navigation').on('click', () => this.pauseVideo());
    $('button').on('click', () => this.pauseVideo());
    $('video').on('play', () => this.pauseVideo());
    $('.youtube-lazy').on('play', () => this.pauseVideo());
    $('.vimeo-lazy').not(this.videoContainer).on('play', () => this.pauseVideo());

    if (!this.checkApi()) {
      // keep checking until player API finished loading
      this.vimeoApiCheck = setInterval(() => {
        if (this.checkApi()) {
          clearInterval(this.vimeoApiCheck);
        }
      }, 100);
    }

    const container = this.videoContainer;
    const thisFunction = this;
    const videoPos = this.videoContainer.offset().top;

    // stop playing once the player fully scrolls out of view
    $(window).on('scroll', () => {
      let containerHeight = container.height();
      let headerHeight = $('div.site-header').height();
      let scrollTop = $(window).scrollTop() + headerHeight;
      let scrollBottom = $(window).scrollTop() + $(window).height();

      if (scrollTop >= (videoPos + containerHeight) || scrollBottom <= videoPos) {
        thisFunction.pauseVideo();
      }
    });
  }

  hidePlayButton () {
    this.playButton.attr('aria-expanded', 'true');
    this.playButton.css('z-index', '-1');
  }

  pauseVideo () {
    if (this.iframe) {
      this.iframe[0].contentWindow.postMessage({'method': 'pause'}, '*');
    }
  }

  /**
   * Lazy Load Vimeo Preview Thumbnails
   */
  loadImage () {
    var that = this;
    let customPoster = this.videoContainer.attr('data-poster');

    if (customPoster) {
      const image = $('<img>', {
        'src': customPoster,
        'alt': that.title
      });
      image.on('load', () => {
        that.videoContainer.append(image);
      });
    }
    else {
      $.getJSON(`https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/${this.videoId}&width=960`, (data) => {
        const image = $('<img>', {
          'src': data['thumbnail_url'],
          'alt': that.title
        });
        image.on('load', () => {
          that.videoContainer.append(image);
        });
      });
    }
  }
}

export {LazyVimeo};
export default LazyVimeo;
